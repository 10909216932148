<template>
  <div class="rankTableWrap" v-if="rankTableShow" :style="{right:myRight,left:myLeft}">
    <div class="commonBox rankTable" :style="{backgroundImage: 'url('+rankBg+')'}">
      <img class="closed" @click="rankTableShow = false" :src="require('@/assets/imgs/public/close.png')" alt="">
      <table class="txtC mt53" v-loading="loading">
        <tr>
          <th width="70">排名</th>
          <th width="138">玩家昵称</th>
        </tr>
        <template v-if="rankData.length>0">
          <tr v-for="(item,index) in rankData" :key="index">
            <td>{{item.rank}}</td>
            <td>{{item.usernick}}</td>
          </tr>
        </template>
        <tr v-else>
          <td colspan="2" style="color: #999">暂无数据</td>
        </tr>
      </table>
      <p class="tableCell" style="margin-top: 8px;">{{title}}</p>
    </div>
  </div>
</template>

<script>
  import axios from "axios";
  import Cookies from "js-cookie";
  import eventBus from '@/common/bus.js'

  export default {
    name:'rankTable',
    props:{
    },
    data() {
      return {
        rankTableShow:true,
        rankBg:require('@/assets/imgs/menu/rankBg.png'),
        loading:false,
        rankData: [],
        myRight:'auto',
        myLeft:'auto',
        title:'',
      }
    },
    created(){
      this.getRankData()
    },
    mounted() {
      this.winResize()
      window.addEventListener('resize',this.winResize)
    },
    methods: {
      winResize(){
        let winW = document.documentElement.clientWidth
        if(winW < 1670){
          this.myRight = 20 + 'px'
          this.myLeft = 'auto'
        }else{
          this.myRight = 'auto'
          this.myLeft = (winW - 1200)/2 + 1220 + 'px'
        }
      },
      getRankData(){
        this.loading=true
        axios({
          method: 'post',
          url: '/recharge/getRechargeRank',
          headers: {
            token: Cookies.get('wx_token'),
          },
          data: {
            platForm: 'web',
            userId: Cookies.get('wx_userId'),     // userId
          },
        }).then((res) => {
          if(res.data.state == 200){
            this.title = res.data.data.title
            this.rankData = res.data.data.rankInfoList
          }else{
            this.$message.error(res.data.message)
          }
        }).catch(err=>{
          this.$message.error(err.message)
        })
        this.loading=false

      },
    },
    watch:{},
  };
</script>

<style lang="less">
  .rankTableWrap{
    position: fixed;
    top:100px;
    z-index:10;
    box-shadow: 0 0 20px #dddddd;
  }
  .rankTable{
    position: relative;
    z-index: 0;
    background-size: 100% auto;
    box-sizing: border-box;
    width:230px;
    max-height: 450px;
    padding: 10px 10px 15px;
    .closed{
      position: absolute;
      right: -18px;
      top:-18px;
      width: 50px;
      cursor:pointer;
    }
    table{
      border-collapse: collapse;
      tr{
        &:nth-child(odd) {
          background:rgba(217,217,217,0.3);
        }
        &:nth-child(even) {
          background:rgba(217,217,217,0);
        }
        th{
          height: 38px;
          line-height: 38px;
          border: #fff 1px solid;
        }
        td{
          height: 30px;
          line-height: 30px;
          border: #fff 1px solid;
        }
      }
    }
    p{
      font-family: PingFang SC, PingFang SC;
      font-weight: 400;
      color:#fff;
      line-height: 22px;
    }
  }
</style>
