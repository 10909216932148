<template>
  <div class="commonBox danceTable pr mt85" :style="{backgroundImage: 'url('+danceBg+')'}">
    <div class="lineTitle pa mt85">
      <div></div>
      <div>每周名人</div>
      <div></div>
    </div>
    <table class="txtC mt100">
      <tr>
        <th width="70">榜单</th>
        <th width="138">舞侠名人</th>
      </tr>
      <tr v-for="(item,index) in rankData" :key="index">
        <td>
          <span style="display: inline-block;width: 100px;white-space: nowrap;text-overflow: ellipsis;overflow: hidden;vertical-align: middle;">{{item.typeName}}</span>
        </td>
        <td>
          <span style="display: inline-block;width: 100px;white-space: nowrap;text-overflow: ellipsis;overflow: hidden;vertical-align: middle;">{{item.usernick}}</span>
        </td>
      </tr>
    </table>
    <div class="overAll mt15 txtC ft20 fw500" @click="goRank">总榜</div>
  </div>
</template>

<script>
  import axios from "axios";
  import Cookies from "js-cookie";
  import eventBus from '@/common/bus.js'

  export default {
    name:'danceTable',
    props:{
    },
    data() {
      return {
        danceBg:require('@/assets/imgs/menu/danceBg.png'),
        rankData: [],
      }
    },
    created(){
      this.getRankData()
    },
    methods: {
      getRankData(){
        axios({
          method: 'post',
          url: '/rank/getLeftFamousRank',
          headers: {
            token: Cookies.get('wx_token'),
          }
        }).then((res) => {
          if(res.data.state == 200){
            this.rankData = res.data.data.rankInfos
          }else{
            this.$message.error(res.data.message)
          }
        }).catch(err=>{
          this.$message.error(err.message)
        })
      },
      goRank(){
        this.$router.push('/hallOfFame')
      },
    },
    watch:{

    },
  };
</script>

<style lang="less">
  .lineTitle{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width:230px;
    left:0;
    top:0;
    div{
      &:nth-child(1),&:nth-child(3){
        width: 80px;
        height: 0;
        border-top:#fff 1px solid;
      }
      &:nth-child(2){
        font-size: 12px;
      }
    }
  }
  .danceTable{
    padding: 10px;
    width:100%;
    background-size: 100% 100%;
    box-sizing: border-box;
    table{
      border-collapse: collapse;
      tr{
        &:nth-child(odd) {
          background:rgba(217,217,217,0.3);
        }
        &:nth-child(even) {
          background:rgba(217,217,217,0);
        }
        th{
          height: 40px;
          line-height: 40px;
          font-size: 20px;
          font-weight: 600;
          color: #fff;
          text-shadow: -1px -1px 0 #94000F, /*左上角阴影*/
          1px -1px 0 #94000F, /*右上角阴影*/
          -1px 1px 0 #94000F, /*左下角阴影*/
          1px 1px 0 #94000F; /*右下角阴影*/
        }
        td{
          height: 32px;
          line-height: 32px;
        }
      }
    }
    .overAll{
      width: 210px;
      height: 34px;
      line-height: 34px;
      background: #8F00FF;
      box-shadow: 0px 4px 4px 0px rgba(255,255,255,0.4);
      border-radius: 100px 100px 100px 100px;
      cursor: pointer;
      font-family: PingFang SC, PingFang SC;
      letter-spacing: 6px;
    }
  }
</style>
