<template>
  <div class="commonBox noticeTable pr" :style="{backgroundImage: 'url('+noticeBg+')'}">
    <div class="tabList mt60">
      <ul>
        <li
          :class="['pb3 ft14 fw500',{'active':curTab==index}]"
          v-for="(item,index) in tabList"
          :key="item.label"
          @click="changeTab(item,index)"
        >{{item.label}}</li>
      </ul>
    </div>
    <table class="txtC mt5 noticRank" v-loading="loading">
      <tr class="head">
        <th width="60">排名</th>
        <th width="110">玩家</th>
        <th width="60">数量</th>
      </tr>
      <template v-if="rankData.length>0">
        <tr v-for="(item,index) in rankData" :key="index">
          <td width="70">
            <img v-if="item.rankId == 1" :src="require('@/assets/imgs/menu/rank1.png')" alt="" height="22">
            <img v-else-if="item.rankId == 2" :src="require('@/assets/imgs/menu/rank2.png')" alt="" height="22">
            <img v-else-if="item.rankId == 3" :src="require('@/assets/imgs/menu/rank3.png')" alt="" height="22">
            <template v-else>{{item.rankId}}</template>
          </td>
          <td width="100" class="noticeTableRankNick">
            <el-tooltip class="item" effect="dark" :content="item.usernick" placement="right">
              <span>{{item.usernick}}</span>
            </el-tooltip>
          </td>
          <td width="60">{{item.num}}</td>
        </tr>
      </template>
      <tr v-else><td style="color: #666">暂无数据</td></tr>
    </table>
    <p class="tableCell">本排行每10分钟更新一次！</p>
  </div>
</template>

<script>
  import axios from "axios";
  import Cookies from "js-cookie";
  import eventBus from '@/common/bus.js'

  export default {
    name:'noticeTable',
    props:{
    },
    data() {
      return {
        noticeBg:require('@/assets/imgs/menu/noticeBg.png'),
        curTab:0,
        pageNum:1,
        pageSize:20,
        type:3,         // 1：普通，2：超级，3：霸屏，4：送花
        tabList:[
          {label:'霸屏',value:3},
          {label:'超级',value:2},
          {label:'普通',value:1},
          {label:'送花',value:4},
        ],
        loading:false,
        rankData: [],
        rankLength:20,//排名有20个数据
      }
    },
    created(){
      this.getRankData()
    },
    methods: {
      getRankData(){
        this.loading=true
        axios({
          method: 'post',
          url: '/notice/getNoticeRank',
          headers: {
            token: Cookies.get('wx_token'),
          },
          data: {
            pageNum:this.pageNum,                 // 页码数
            pageSize:this.pageSize,               // 每页显示条数。默认10条
            type:this.type,                       // 排序类型,1：普通，2：超级，3：霸屏，4：送花
            platForm: 'web',
            userId: Cookies.get('wx_userId'),     // userId
          },
        }).then((res) => {
          if(res.data.state == 200){
            this.rankData = res.data.data.rankInfos
          }else{
            this.$message.error(res.data.message)
          }
        }).catch(err=>{
          this.$message.error(err.message)
        })
        this.loading=false

      },
      changeTab(item,index){
        this.curTab=index
        this.type = item.value
        this.getRankData()
      },
    },
    watch:{

    },
  };
</script>

<style lang="less">
  .noticeTable{
    padding: 10px 0;
    width:100%;
    background-size: 100% auto;
    box-sizing: border-box;
    .tabList{
      padding: 0 10px;
      ul{
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
      li{
        font-family: PingFang SC, PingFang SC;
        cursor: pointer;
        color:#333;
        &.active{
          color:#D22190;
          border-bottom: #D22190 2px solid;
        }
      }
    }
    .noticRank{
      width: 100%;
      max-height: 670px;
      min-height: 150px;
      overflow: hidden;
      border-collapse: collapse;
      .head{
        display: block;
        margin: 0 10px;
        width: 210px;
        background:rgba(217,217,217,0.3);
        th{
          height: 32px;
          line-height: 32px;
          color:#D22190;
        }
      }
      tr{
        display: block;
        width: 100%;
        td{
          display: inline-block;
          color: #333;
          height: 30px;
          line-height:30px;
          img{
            vertical-align: middle;
          }
        }
        &:nth-child(odd) {
          background:rgba(217,217,217,0.3);
        }
        &:nth-child(even) {
          background:rgba(217,217,217,0);
        }
      }
      .noticeTableRankNick{
        span{
          display: inline-block;
          width: 100px;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          vertical-align: middle;
        }
      }
    }
    p{
      font-family: PingFang SC, PingFang SC;
      padding: 0 10px;
      font-weight: 400;
      color:#ff0000;
      line-height: 22px;
    }
  }
</style>

