<template>
  <div class="commonBox bgmTable pr" :style="{backgroundImage: 'url('+bgmBg+')'}">
    <div class="tabList mt60">
      <ul>
        <li 
          :class="['pb3 ft14 fw500',{'active':curTab==index}]" 
          v-for="(item,index) in tabList" 
          :key="item.label"
          @click="changeTab(item,index)"
        >{{item.label}}</li>
      </ul>
    </div>
    <table class="txtC mt5" v-loading="loading">
      <tr>
        <th width="70">排名</th>
        <th width="74">玩家</th>
        <th width="74">评分</th>
      </tr>
      <tr v-for="(item,index) in rankData" :key="index">
        <td>{{item.order}}</td>
        <td>{{item.name}}</td>
        <td>{{item.record}}</td>
      </tr>
    </table>
  </div>
</template>

<script>
  import axios from "axios";
  import Cookies from "js-cookie";
  import eventBus from '@/common/bus.js'

  export default {
    name:'bgmTable',
    props:{
    },
    data() {
      return {
        bgmBg:require('@/assets/imgs/menu/bgmBg.png'),
        curTab:0,
        curValue:1,
        tabList:[
          {label:'赛季霸王',value:1},
          {label:'榜单之巅',value:2},
          {label:'打榜先锋',value:3},
        ],
        loading:false,
        rankData: [],
        rankLength:10,//排名有10个数据
      }
    },
    created(){
      this.getRankData()
    },
    methods: {
      initRankData(){
        this.rankData=[]
        for(let i=0; i<this.rankLength;i++){
          let item={
            index:i+1,
            name:'',
          }
          this.rankData.push(item)
        }
      },
      getRankData(){
        this.loading=true
        this.initRankData()
        let data=[
          {order:1,name: '小羊1号',record:120000},
          {order:2,name: '小羊2号',record:120000}, 
          {order:3,name: '小羊3号',record:120000},
        ]
        setTimeout(()=>{
          this.rankData=Object.assign(this.rankData,data)
          this.loading=false
        },500)
      },
      changeTab(item,index){
        this.curTab=index
        this.curValue=item.value
        this.getRankData()
      }
    },
    watch:{
      
    },
  };
</script>

<style lang="less">
  .bgmTable{
    padding: 10px;
    width:100%;
    height:460px;
    background-size: 100% 100%;
    box-sizing: border-box;
    .tabList{
      ul{
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
      li{
        font-family: PingFang SC, PingFang SC;
        cursor: pointer;
        &.active{
          border-bottom: #fff 2px solid;
        } 
      }
    }
    table{
      border-collapse: collapse;
      tr{
        &:nth-child(odd) {
          background:rgba(217,217,217,0.3);
        }
        &:nth-child(even) {
          background:rgba(217,217,217,0);
        }
        th{
          height: 32px;
          line-height: 32px;
        }
        td{
          height: 32px;
          line-height: 32px;
        }
      }
    }
  }
</style>
