<template>
  <div class="loginWrap">
    <div class="login">
      <el-form ref="loginForm" :model="loginForm" :rules="loginFormRules" :label-width="type == 0 ? '100px' : '0'">
        <el-form-item :label="type == 0 ? '游戏账号:' : ''">
          <el-input v-model="loginForm.username" prop="username" maxlength="12"
            :placeholder="loginPlaceholder[type].name">
            <i slot="prefix" v-if="type == 1">
              <img class="header-icon" style="height: 26px" :src="login1" />
            </i>
          </el-input>
        </el-form-item>
        <el-form-item :label="type == 0 ? '登录密码:' : ''" prop="password">
          <el-input v-model="loginForm.password" type="password" maxlength="18"
            :placeholder="loginPlaceholder[type].psw">
            <i slot="prefix" v-if="type == 1">
              <img class="header-icon" style="height: 26px" :src="login2" />
            </i>
          </el-input>
        </el-form-item>
        <el-form-item :label="type == 0 ? '验证码:' : ''" prop="code">
          <div class="codeWrap">
            <el-input v-model="loginForm.code" :placeholder="loginPlaceholder[type].code">
              <i slot="prefix" v-if="type == 1">
                <img class="header-icon" style="height: 26px" :src="login3" />
              </i>
            </el-input>
            <span class="codeImg" @click="getCaptcha"><img :src="codeImg" alt="" width="58" height="36" /></span>
          </div>
        </el-form-item>
        <el-form-item class="submitWrap">
          <el-button class="btn" type="primary" @click="login('loginForm')">{{
            "立即登录"
          }}</el-button>
          <el-button v-if="type == 0" class="btn" @click="goRegister">注册</el-button>
        </el-form-item>
        <div class="forgetPsw">
          <span @click="forgetPsw">无法登录？点击找回</span>
          <span v-if="type == 0" @click="goRegister">马上注册，送好礼</span>
        </div>
      </el-form>
    </div>
  </div>
</template>

<script>
import Cookies from "js-cookie";
import axios from "axios";
import eventBus from "@/common/bus.js";
export default {
  name: "login",
  props: {
    type: "", // 0:显示的是登录页的样式。1:显示的是首页的样式
  },
  data() {
    return {
      isShowPask: true,
      showSwitch: 2,
      login1: require("@/assets/imgs/menu/login1.png"),
      login2: require("@/assets/imgs/menu/login2.png"),
      login3: require("@/assets/imgs/menu/login3.png"),
      loginPlaceholder: [
        {
          name: "由6~12位数字或字母组成",
          psw: "由6~18位数字、字母、字符组成",
          code: "验证码",
        },
        { name: "账号", psw: "密码", code: "验证码" },
      ],
      codeImg: "",
      loginForm: {
        username: "",
        password: "",
        code: "",
        type: 1,
        source: "web",
      },
      loginFormRules: {
        username: [
          { required: true, message: "请输入游戏账号", trigger: "blur" },
          {
            required: true,
            pattern: /^([0-9A-Za-z]){6,12}$/,
            message: "游戏账号由6~12位数字或字母组成",
            trigger: "blur",
          },
        ],
        password: [
          { required: true, message: "请输入密码", trigger: "blur" },
          {
            required: true,
            pattern: /^[\w!@#$%^&*()-+=<>?]{6,18}$/i,
            message: "密码由6~18位数字、字母、字符组成",
            trigger: "blur",
          },
        ],
        code: [{ required: true, message: "请输入验证码", trigger: "blur" }],
      },
    };
  },
  created() {
    this.getCaptcha();
  },
  mounted() {
    // this.loginCopy();
  },
  methods: {
    forgetPsw() {
      this.$emit("forgetPsw");
    }, // 忘记密码
    getCaptcha() {
      // 获取图片验证码
      axios({
        method: "get",
        url: "/login/getCaptcha",
        xhrFields: {
          withCredentials: true,
        },
        responseType: "arraybuffer",
      }).then((res) => {
        const setCookie = res.headers["Set-Cookie"];
        let imageType = res.headers["content-type"]; //获取图片类型
        const blob = new Blob([res.data], { type: imageType });
        const imageUrl = (window.URL || window.webkitURL).createObjectURL(blob);
        this.codeImg = imageUrl;
      });
    },

    login(loginForm) {
      // 立即登录
      let _this = this;
      this.$refs[loginForm].validate((valid) => {
        if (valid) {
          axios({
            method: "post",
            url: "/login/doLogin",
            xhrFields: {
              withCredentials: true,
            },
            data: this.loginForm,
          })
            .then((res) => {
              if (res.data.state == 200) {
                // console.log(res.data.message);
                this.$message.success(res.data.message);
                let seconds = 8 * 60 * 60; // 2*60*60;   2小时
                let expires = new Date(new Date() * 1 + seconds * 1000);
                this.resetSetItem("userInfo", JSON.stringify(res.data.data));

                Cookies.set("wx_token", res.data.data.token, { domain: ".qtthtech.com", expires: expires, });
                Cookies.set("wx_userId", res.data.data.userId, { domain: ".qtthtech.com", expires: expires, });
                // Cookies.set("wx_token", res.data.data.token, { expires: expires, });
                // Cookies.set("wx_userId", res.data.data.userId, { expires: expires,});
                this.$emit("loginSuccess");
                if (this.type == 0) {
                  this.$router.push({
                    path: "/",
                  });
                }
              } else {
                this.$message.error(res.data.message);
                this.getCaptcha();
              }
            })
            .catch((err) => {
              this.$message.error(err.message);
            });
        }
      });
    },
    goRegister() {
      // 去注册页面
      this.$router.push({
        path: "/register",
      });
    },
  },
};
</script>

<style scoped lang="less">
.codeWrap {
  display: flex;

  .codeImg {
    margin-left: 10px;
  }
}
</style>
